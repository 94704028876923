<template>
  <v-dialog v-model="dialog" width="auto" fullscreen persistent>
    <div class="barcode-reader-wrap">
      <div class="modal-barcode-scanner">
        <div class="modal-content">
          <div class="modal-body">
            <v-snackbar
              v-model="snackbar"
              :timeout="-1"
              class="v-snack--error"
              top
              right
            >
              {{ message }}
            </v-snackbar>
            <div class="container-camera">
              <div class="camera-content recognize">
                <div class="camera-content-video">
                  <div class="camera-focus">
                    <div class="camera-focus-area">
                      <div class="camera-focus-corner"></div>
                      <div class="camera-focus-corner"></div>
                    </div>
                  </div>
                </div>
                <div class="wrap_video viewport"></div>
                <div class="barcode-label" v-if="code">{{ toastCode }}</div>
              </div>
              <!-- <input type="text" v-model="code" /> -->
              <div class="group-button">
                <v-container>
                <!-- <v-btn
                      depressed
                      outlined
                      @click="test"
                      >test</v-btn
                    > -->
                  <v-row>
                    <v-col :cols="retry ? 6 : 12">
                      <v-btn
                        depressed
                        outlined
                        @click="close"
                        >{{ $t('common.button.back') }}</v-btn
                      >

                    </v-col>
                    <v-col cols="6" v-if="retry">
                      <v-btn
                        class="success"
                        color="success"
                        depressed
                        @click="onClickRetry"
                        >{{ $t('card.button.retry') }}</v-btn
                      >
                    </v-col>
                  </v-row>

              </v-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Quagga from '@ericblade/quagga2';
import md from '@/services/mobileDetect';
import * as apiResponse from '@/configs/apiResponse';
import CardService from '@/services/cardService';
import { minBy } from 'lodash';

const MIN_CARD_NUMBER_LEN = 8;
const TYPE_CARD = 'card';
const TYPE_PROMOTION = 'promotion';
const TYPE_TRANSACTION = 'transaction';
const CODE_TYPE_BARCODE = 'barcode';
const CODE_FORMAT_CODABAR = 'codabar';
// const CODE_TYPE_QR = 'qr';
/*
  :code-type="['barcode','qr']"
  :type="promotion|card"
  :validate-options="['spec', 'balance']"
  :validate-options="[]" // skip validate
  :validate-options="['spec']"
*/
const VALIDATE_OPTION_SPEC = 'spec';
const VALIDATE_OPTION_BALANCE = 'balance';

export default {
  name: 'DialogBarcodeScan',
  props: {
    type: {
      type: String,
      default: TYPE_CARD,
    },
    codeType: {
      type: Array,
      default: function () {
        return [CODE_TYPE_BARCODE];
      },
    },
    validateOptions: {
      type: Array,
      default: function () {
        return [VALIDATE_OPTION_SPEC, VALIDATE_OPTION_BALANCE];
      },
    },
    retry: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      code: '',
      isDone: false,
      code1: '', // for test
      dialog: false,
      camera: {
        deviceId: '',
        isHasPermission: false,
        isTurnOnTorch: false,
      },
      barCodeReader: {
        code: '',
        result: {},
      },
      balance: {},
      message: '',
      windowHeight: window.innerHeight,
      isIOS: false,
      initData: {},
      snackbar: false,
    };
  },
  watch: {
    async code (newValue) {
      if (newValue) {
        if (this.barCodeReader && this.barCodeReader.result && this.barCodeReader.result.format === CODE_FORMAT_CODABAR) {
          this.barCodeReader.result.code = this.barCodeReader.result.code.replace(/[\D]/g, '');
        }
        this.stopBarcodeReader();
        switch (this.type) {
          case TYPE_CARD:
            this.processTypeCard();
            break;
          case TYPE_PROMOTION:
            this.processTypePromotion();
            break;
          case TYPE_TRANSACTION:
            this.processTypeTransaction();
            break;

          default:
            break;
        }
      }
    },
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },
  async created () {
    this.isIOS = md.isIOS();
  },
  methods: {
    ...mapActions({
      // addError: 'snackbar/addError',
      getBalance: 'cardStore/getBalance',
      getPromotionByBarcode: 'promotionStore/getByBarcode',
    }),
    async processTypeCard () {
      if (this.validateOptions.length === 0) {
        console.log('validateOptions len 0');
        this.emitResult();
      }

      // config validateOptions: ['spec']
      if (this.validateOptions.indexOf(VALIDATE_OPTION_SPEC) !== -1) {
        const cardServiceInstace = new CardService();
        cardServiceInstace.setCardSpecs(this.user.store.company.client.cards);
        const validateResult = cardServiceInstace.validateBarcode(this.barCodeReader.result);
        // validateOptions only spec
        if (this.validateOptions.length === 1) {
          if (validateResult) {
            console.log('validateOptions len 1');
            this.isDone = true;
            this.goToTransactionMenu();
          } else {
            this.showInvalidateMessage();
          }
        } else if (this.validateOptions.indexOf(VALIDATE_OPTION_BALANCE) !== -1) {
          if (!validateResult) {
            this.showInvalidateMessage();
          } else if (this.systemHasApi) {
            // api check balance
            const resBalance = await this.getBalance(this.barCodeReader.result.card_number);
            // console.log('resBalance', resBalance);
            const { code, data, status } = resBalance;
            let isMove = true;
            if (code === apiResponse.ERROR_CODE_NOT_FOUND) {
              this.showInvalidateMessage();
              isMove = false;
            }

            if (status) {
              const { status: finalCardStatus, error_message: errorMessage, balance } = data.result;
              this.balance = balance;
              if (finalCardStatus === this.$constant.card_spec.FINAL_STATUS_INVALID) {
                this.showInvalidateMessage(errorMessage);
                isMove = false;
              }
            }
            // To do : case offline, case timeout
            // console.log('isMove', isMove);
            if (isMove) {
              this.isDone = true;
              this.goToTransactionMenu();
            }
          } else {
            this.isDone = true;
            this.goToTransactionMenu();
          }
        }
      }
    },
    async processTypePromotion () {
      const response = await this.getPromotionByBarcode(this.code);
      const { data, status } = response;
      if (!status) {
        const errorMessage = data ? data.message : this.$t('common.message.error');
        this.showInvalidateMessage(errorMessage);
        return;
      }
      this.close();
      this.$emit('data', data.result.data);
    },
    async processTypeTransaction () {
      this.barCodeReader.result.code = this.barCodeReader.result.code.replace(/[\D]/g, '');
      const cardServiceInstace = new CardService();
      cardServiceInstace.setCardSpecs(this.user.store.company.client.cards);
      this.code = cardServiceInstace.handleBarcode(this.barCodeReader.result);
      this.emitResult();
    },
    onClickRetry () {
      if (this.code.length > 0) {
        this.initValue();
        this.initBarcodeReader();
      }
      this.snackbar = false;
    },
    initValue () {
      this.code = '';
      this.barCodeReader = {
        code: '',
        result: {},
      };
      this.message = '';
      this.snackbar = false;
    },
    emitResult () {
      this.close();
      this.$emit('code', {
        code: this.code,
        scanDatas: this.barCodeReader.result,
        balance: this.balance,
      });
    },
    goToTransactionMenu (data) {
      this.$router.push({
        name: 'transaction_menu',
        params: {
          card_number: this.barCodeReader.result.card_number,
          balance: this.balance,
        },
      });
    },
    showInvalidateMessage (errorMessage = '') {
      if (errorMessage === '') {
        this.message = this.$t('common.message.invalid', { itemName: this.$t('card.label.card_no') });
      } else {
        this.message = errorMessage;
      }
      this.snackbar = true;
    },
    getCameraId () {
      return new Promise((resolve) => {
        navigator.mediaDevices
          .enumerateDevices()
          .then(function (deviceInfos) {
            const videoInputs = deviceInfos.filter(
              (d) => d.kind === 'videoinput',
            );
            if (videoInputs.length > 0) {
              const hiqualityCamera = videoInputs.filter(
                (v) => {
                  const lowerCaseLabel = v.label ? v.label.toLowerCase() : '';
                  return lowerCaseLabel.indexOf('back') !== -1 || v.label.indexOf('背面') !== -1 || v.label.indexOf('2 0') !== -1;
                });
              resolve(
                hiqualityCamera.length > 0
                  ? hiqualityCamera[0].deviceId
                  : videoInputs[videoInputs.length - 1].deviceId,
              );
            } else {
              resolve();
            }
          });
      });
    },
    askCameraPermission () {
      return new Promise((resolve) => {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: true })
          .then(function () {
            return resolve(true);
          })
          .catch(function () {
            return resolve(false);
          });
      });
    },
    async requestCameraFirstTime () {
      const promptCamera = await this.askCameraPermission();
      if (promptCamera) {
        // this.addError(this.$t('card.errors.reload_and_scan'));
        this.showBarCodeReader();
      }
    },
    async show () {
      await this.showBarCodeReader();
    },
    initBarcodeReader () {
      Quagga.init(
        {
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: document.querySelector('.viewport'), // Or '#yourElement' (optional)
            constraints: {
              width: {
                min: 1280,
                ideal: 1920,
                max: 2560,
              },
              height: {
                min: 720,
                ideal: 1080,
                max: 1440,
              },

              // facingMode: 'environment',
              deviceId: this.camera.deviceId,
            },
            area: {
              // defines rectangle of the detection/localization area
              top: '20%', // top offset
              right: '10%', // right offset
              left: '10%', // left offset
              bottom: '20%', // bottom offset
            },
            singleChannel: false, // true: only the red color-channel is read
          },
          decoder: {
            readers: ['code_128_reader', 'ean_reader', 'codabar_reader', 'ean_8_reader'],
          },
          numOfWorkers: 1,
          locator: {
            halfSample: true,
            patchSize: 'large',
          },
        },
        function (err) {
          if (err) {
            console.log(err);
            return;
          }
          Quagga.start();
        },
      );
      Quagga.onDetected(
        async function (data) {
          console.log(' onDetected barcode : ' + data.codeResult.code);
          console.log('data 2: ', data);

          if (this.isDone) {
            this.isDone = false;
            this.code = '';
          }
          if (data && data.codeResult && data.codeResult.code && this.type === TYPE_PROMOTION) {
            // task 586: promotion barcode does not have check length
            if (this.code === '') {
              this.code = data.codeResult.code;
              this.barCodeReader.result = data.codeResult;
            }
          } else if (data && data.codeResult && data.codeResult.code && data.codeResult.code.length >= this.minCardNumberLength) {
            // only card number need to check length with card specs
            if (this.code === '') {
              this.code = data.codeResult.code;
              this.barCodeReader.result = data.codeResult;
            }
          } else {
            // console.log('ma khong dung');
          }
        }.bind(this),
      );
    },
    stopBarcodeReader () {
      Quagga.offDetected(function () {
      });
      Quagga.stop();
    },

    async showBarCodeReader () {
      if (!this.camera.deviceId) {
        this.camera.deviceId = await this.getCameraId();
      }
      await this.initBarcodeReader();
    },
    onClickSwitchFlash () {
      if (!document.querySelector('.viewport video')) {
        return '';
      }

      this.camera.isTurnOnTorch = !this.camera.isTurnOnTorch;
      document
        .querySelector('.viewport video')
        .srcObject.getVideoTracks()[0]
        .applyConstraints({
          advanced: [{ torch: this.camera.isTurnOnTorch }],
        });
    },
    hide () {
      // this.$emit('input', this.barCodeReader.bar_codes);
      this.barCodeReader.isShow = false;
      this.stopBarcodeReader();
    },
    getUniqueListBy (arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },

    async open (data = null) {
      this.dialog = true;
      this.initData = data;
      this.initValue();
      if (this.isIOS) {
        this.showBarCodeReader();
      } else {
        const camPermission = await this.checkCameraPermission();
        switch (camPermission) {
          case 'denied':
            this.camera.isHasPermission = false;
            break;

          case 'prompt':
            await this.requestCameraFirstTime();
            break;

          // case granted
          default:
            this.showBarCodeReader();
            break;
        }
      }
    },
    async checkCameraPermission () {
      return new Promise((resolve) => {
        if (navigator.permissions && navigator.permissions.query) {
          navigator.permissions.query({ name: 'camera' }).then(async (res) => {
            return resolve(res.state);
          });
        }
      });
    },
    async close () {
      await this.stopBarcodeReader();
      this.$emit('close');
      this.dialog = false;
    },
    async destroyed () {
      await this.stopBarcodeReader();
    },
  },
  computed: {
    ...mapGetters({
      user: 'userStore/user',
    }),
    toastCode () {
      if (this.type === TYPE_CARD) {
        if (this.barCodeReader && this.barCodeReader.result && this.barCodeReader.result.card_number) {
          return this.barCodeReader.result.card_number;
        }
      }
      return this.code;
    },
    systemHasApi () {
      if (this.user && this.user.store) {
        return this.user.store.company.client.system.api_available === this.$constant.system.API_AVAILABLE;
      }
      return false;
    },
    cameraContentStyle () {
      const footerHeight = 92;
      const contentHeight = window.innerHeight - footerHeight;
      return {
        height: (contentHeight || 0) + 'px !important',
      };
    },
    cameraFocusAreaStyle () {
      const innerHeight = window.innerHeight;
      return {
        borderTopWidth: ((innerHeight - 380) / 2) + 'px !important',
        borderBottomWidth: ((innerHeight - 380) / 2) + 'px !important',
      };
    },
    // MIN_CARD_NUMBER_LEN
    minCardNumberLength () {
      const card = minBy(this.user.store.company.client.cards, 'number_of_digits_id');
      if (card && card.number_of_digits_id) {
        console.log('minCardNumberLength is', card.number_of_digits_id);
        return card.number_of_digits_id;
      }
      return MIN_CARD_NUMBER_LEN;
    },
  },
  onResize () {
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style scoped>
  .group-button {
    background: #1d1d1d;
    padding: 8px;
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  >>> .modal-barcode-scanner .modal-content {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  >>> .modal-barcode-scanner .modal-body {
    padding: 0;
  }

  .container-camera {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  >>> .modal-barcode-scanner .camera-content {
    flex-grow: 1;
    position: relative;
    height: 100vh;
  }

  >>> .modal-barcode-scanner .camera-content.recognize {
    overflow: hidden;
    background: rgba(0,0,0,.6);
  }

  /* camera-focus-area */
  .camera-focus {
    flex-grow: 1;
  }

  .camera-focus-area {
    box-sizing: content-box;
    position: relative;
    width: 280px;
    height: 280px;
    border-color: #56514b85;
    border-style: solid;
    border-top-width: calc((100vh - 280px - 92px) / 2);
    border-bottom-width: calc((100vh - 280px - 92px) / 2);
    border-right-width:  calc((100vw - 280px) / 2);
    border-left-width:  calc((100vw - 280px) / 2);
  }

  /* .camera-focus-area:before,
  .camera-focus-area:after, */
  .camera-focus-area .camera-focus-corner:first-child:before,
  .camera-focus-area .camera-focus-corner:first-child:after,
  .camera-focus-area .camera-focus-corner:nth-child(2):before,
  .camera-focus-area .camera-focus-corner:nth-child(2):after {
    position: absolute;
    width: 28px;
    height: 28px;
    content: '';
    background-size: 100% 100%;
  }

  .camera-focus-area .camera-focus-corner:first-child:before {
    top: 0;
    left: 0;
    background-image: url(@/assets/icons/ico_corner_top_left.svg);
  }

  .camera-focus-area .camera-focus-corner:first-child:after {
    top: 0;
    right: 0;
    background-image: url(@/assets/icons/ico_corner_top_right.svg);
  }

  .camera-focus-area .camera-focus-corner:nth-child(2):before {
    bottom: 0;
    left: 0;
    background-image: url(@/assets/icons/ico_corner_bottom_left.svg);
  }

  .camera-focus-area .camera-focus-corner:nth-child(2):after {
    bottom: 0;
    right: 0;
    background-image: url(@/assets/icons/ico_corner_bottom_right.svg);
  }

  .camera-content-video {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
  }

  .viewport {
    /* background: turquoise; */
  }

  >>> .wrap_video {
    width: 100%;
    height: 100%;
    display: flex;
  }

  >>> .wrap_video video {
    width: 100%;
    height: auto !important;
    /* z-index: 999; */
    overflow: hidden;
    -webkit-transform-style: preserve-3d;
    object-fit: cover;
  }

  >>> .wrap_video canvas.drawingBuffer {
    display: none;
  }

  >>> .camera-content.manual video,
  >>> .camera-content.manual canvas.drawingBuffer {
    display: none;
  }

  .modal-barcode-scanner .modal-content {
    z-index: 2;
  }
  .barcode-label {
    position: fixed;
    left: 50%;
    top: calc(50vh + 120px);
    transform: translateX(-50%);
    height: auto;
    background: #1d1d1d;
    min-width: 195px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 12px;
    color: #fff;
    font-size: 17px;
    z-index: 999;
  }
</style>
